Dropzone.autoDiscover = false;

Dropzone.prototype.defaultOptions.dictDefaultMessage = "Upuść zdjęcie tutaj";
Dropzone.prototype.defaultOptions.dictFallbackMessage = "Twoja przeglądarka nie pozwala na upuszczanie plików.";
Dropzone.prototype.defaultOptions.dictFallbackText = "Please use the fallback form below to upload your files like in the olden days.";
Dropzone.prototype.defaultOptions.dictFileTooBig = "Plik jest za duży ({{filesize}}MiB). Maksymalny rozmiar pliku: {{maxFilesize}}MiB.";
Dropzone.prototype.defaultOptions.dictInvalidFileType = "Nie możesz wysyłać plików z tym rozszerzeniem.";
Dropzone.prototype.defaultOptions.dictResponseError = "Serwer odpowiedział kodem {{statusCode}}.";
Dropzone.prototype.defaultOptions.dictCancelUpload = "Anuluj wysyłanie";
Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Czy na pewno chcesz anulować wysyłanie?";
Dropzone.prototype.defaultOptions.dictRemoveFile = "Usuń plik";
Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "Nie możesz załączyć więcej zdjęć";

var formErrors = [],
    clothesDropzone,
    dzClosure
;

$(document).ready(function () {
    dropzoneInit();
    goToSelector();
});

function dropzoneInit() {
    clothesDropzone = $("#clothes-dropzone").dropzone({
        url: '/file-upload.php',
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 10,
        maxFiles: 10,
        maxFilesize: 10,
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        initold: function () {
            dzClosure = this;

            this.on('addedfile', function () {
                $('label[for=upload]').removeClass('error-field').find('em').hide();
            });

            this.on("sendingmultiple", function (data, xhr, formData) {
                formData.append("email", $("#email").val());
                formData.append("message", $("#message").val());
            });

            this.on('maxfilesexceeded', function (file) {
                this.removeFile(file);
            });

            this.on('success', function (file) {
                $('.send-message').hide();
                $('form .holder').fadeOut(function () {

                });
                $('.send-message').html('Dziękujemy! Wiadomość została wysłana!<br>Wycenę otrzymasz na podany adres mailowy w&nbsp;ciągu 3&nbsp;dni&nbsp;roboczych.').fadeIn();
            });

            this.on('error', function (file) {
                $('.send-message').hide();
                $('form .holder').fadeOut(function () {

                });
                $('.send-message').html('Wiadomośc nie zostala wysłana poprawnie.<br>Spróbuj ponownie lub skontaktuj się z nami.').fadeIn();
            });
        },
        init: function () {
            var myDropzone = this;

            // First change the button to actually tell Dropzone to process the queue.
            document.querySelector("[type=submit]").addEventListener("click", function (e) {
                // Make sure that the form isn't actually being sent.
                e.preventDefault();
                e.stopPropagation();

                if (!isFormValid())
                    return false;

                // Get the recaptcha input
                var cap_input = grecaptcha.getResponse();
                formThinkin(true);
                $.ajax({
                    url: '/check.php',
                    dataType: "JSON",
                    type: "POST",
                    data: {
                        "g-recaptcha-response": cap_input
                    },
                    success: function (response) {
                        if (response == '1')
                            myDropzone.processQueue();
                        else {
                            showError('recaptcha', 'Pole wymagane');
                            formThinkin(false);
                        }
                    }
                });
            });

            this.on('addedfile', function () {
                $('label[for=upload]').removeClass('error-field').find('em').hide();
                formThinkin(false);
            });

            this.on("sendingmultiple", function (data, xhr, formData) {
                formData.append("email", $("#email").val());
                formData.append("message", $("#message").val());
            });

            this.on('maxfilesexceeded', function (file) {
                this.removeFile(file);
            });

            this.on('success', function () {
                scrollToId('clothes-form-anchor');
                $('.send-message').hide();
                $('form .holder').fadeOut(function () {
                    $('.send-message').html('Dziękujemy! Wiadomość została wysłana! Wycenę otrzymasz na podany adres mailowy w ciągu 3 dni roboczych.').fadeIn();
                    formThinkin(false);
                });
            });

            this.on('error', function (file) {
                if(file instanceof File)
                    return;

                scrollToId('clothes-form-anchor');
                $('.send-message').hide();
                $('form .holder').fadeOut(function () {
                    $('.send-message').html('Wiadomośc nie zostala wysłana poprawnie. Spróbuj ponownie lub skontaktuj się z nami.').fadeIn();
                    formThinkin(false);
                });
            });

            $('#clear-dropzone').on('click', function () {
                clothesDropzone[0].dropzone.removeAllFiles();
            });

            $('#upload-dropzone').on('click', function () {
                clothesDropzone[0].dropzone.hiddenFileInput.click();
            });
        }
    });
}

function formThinkin(think) {
    if(think === true) {
        $('#clothes-form').addClass('thinkin');
    } else {
        $('#clothes-form').removeClass('thinkin');
    }
}

function isFormValid() {

    formErrors = [];
    var form = $('#clothes-form'),
        fEmail = form.find('[name=email]'),
        fMessage = form.find('[name=message]'),
        fAgree = form.find('[name=agree]');

    $('.error-msg').hide();
    $('.error-field').removeClass('error-field');

    if (fEmail.val().trim() === '')
        showError('email', 'Pole wymagane');
    else if (!validateEmail(fEmail.val()))
        showError('email', 'Nieprawidłowy adres e-mail');

    if (fMessage.val().trim() === '')
        showError('message', 'Pole wymagane');

    if (!clothesDropzone[0].dropzone.files || !clothesDropzone[0].dropzone.files.length)
        showError('upload', 'Dodaj zdjęcia');

    if (!fAgree.is(':checked'))
        showError('agree', 'Pole wymagane');

    return formErrors.length === 0;
}

function showError(fieldId, message) {
    formErrors.push(fieldId);
    $('#clothes-form')
        .find('label[for="' + fieldId + '"]').addClass('error-field')
        .find('.error-msg').text(message).show();
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function goToSelector() {
    $('.go-to').on('click mousedown', function () {
        var targetId = $(this).data('target-id'),
            shift = $(this).data('shift') ? $(this).data('shift') : 0;
        scrollToId(targetId, shift);
    });
}

function scrollToId(targetId, shift) {
    $("html, body").animate({
        scrollTop: $('#' + targetId).offset().top + (shift ? shift : 0)
    }, 500);
}